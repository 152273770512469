import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { createQuotations, QuotationCreateData } from '../../../api/smartbestbuys-api'
import { withLayout } from '../../../custom-components'
import { PageName, QuotationsOrderCreateState } from '../../../enums'
import { useQuotationsCreateData, useUserData } from '../../../hooks'
import { Loading } from '../../../shared-components'
import { QuotationsCreateValue } from './QuotationsCreateValue'
import QuotationsCreationForm from './quotations-create-form/QuotationsCreationForm'
import QuotationsSummarizeForm from './quotation-summarize-form/QuotationsSummarizeForm'
import { mapQuotationsCreateValueToQuotationCreationRequest } from './mapper'
import { RootContext } from '../../..'
import { useHistory } from 'react-router-dom'
import { CurrencyID } from '../../../types'
import { BaseCustomerProfileDomain, mapCustomerProfileDomain } from '../../../data'
import { UserData } from '../../../api/smartbestbuys-api/data/data-types'

interface QuotationsCreationPageContext {
  customerId: number
  contactId?: number
  quotationCreateData: QuotationCreateData
  customerProfile: BaseCustomerProfileDomain
  users: UserData[]
  ticketId?: number
}

export const QuotationsCreationPageContextImpl = createContext<QuotationsCreationPageContext>({
  customerId: 0,
  contactId: undefined,
  quotationCreateData: {} as QuotationCreateData,
  customerProfile: {} as BaseCustomerProfileDomain,
  users: [] as UserData[],
  ticketId: undefined,
})

interface QueryParams {
  customerId: string
  contactId?: string
  quotationTicketId?: string
}

interface Props {
  query: QueryParams
  width: Breakpoint
}

const QuotationsCreationPage: React.FC<Props> = (props) => {
  // props
  const { query } = props
  const customerId = Number(query.customerId)
  const contactId = query.contactId ? Number(query.contactId) : undefined
  const ticketId = query.quotationTicketId ? Number(query.quotationTicketId) : undefined

  const [state, setState] = useState(QuotationsOrderCreateState.FORM)

  const { triggerSnackbar, setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.QUOTATION)

  const history = useHistory()

  // hook form
  const formMethod = useForm<QuotationsCreateValue>({
    defaultValues: {
      selectedProducts: [],
      periodDay: 30,
      exchangeRate: 1,
      vatIncluded: true,
      currencyId: CurrencyID.Baht,
      quotationTicketId: ticketId,
    },
  })

  const { register, unregister, handleSubmit } = formMethod

  useEffect(() => {
    register('paymentMethodId', {
      required: { value: true, message: 'กรุณาเลือกวิธีการชำระเงิน' },
      valueAsNumber: true,
    })
    register('dueDate', {
      required: {
        value: true,
        message: 'กรุณาเลือกวันที่ลูกค้ารับของ',
      },
      valueAsDate: true,
    })
    return () => {
      unregister('paymentMethodId')
    }
  }, [register, unregister])

  // hook
  const quotationCreateData = useQuotationsCreateData(customerId, contactId)
  const userData = useUserData()
  if (!quotationCreateData || !userData) return <Loading />

  // TODO: refactor this
  const customerProfile = mapCustomerProfileDomain(
    quotationCreateData.customer,
    quotationCreateData.billingAddresses.map((billingAddresses) => {
      return {
        ...billingAddresses,
        recCreatedAt: new Date(),
        recCreatedBy: 'removable',
      }
    }),
    quotationCreateData.contact ? [quotationCreateData.contact] : undefined,
  )

  const handleOnSubmitSummarize = async (values: QuotationsCreateValue) => {
    const request = mapQuotationsCreateValueToQuotationCreationRequest(customerId, contactId, values)
    const response = await createQuotations(request)
    if (response?.status === 201) {
      triggerSnackbar('สร้างใบเสนอราคาสำเร็จ')
      const data = response.data
      history.push(`/quotations/${data.id}/profile`)
    } else {
      triggerSnackbar('สร้างใบเสนอราคาไม่สำเร็จ')
    }
    return true
  }

  const render = (): ReactNode => {
    switch (state) {
      case QuotationsOrderCreateState.FORM:
        return (
          <QuotationsCreationForm
            onNextPage={() => {
              window.scrollTo(0, 0)
              setState(QuotationsOrderCreateState.SUMMARIZE)
            }}
          />
        )
      case QuotationsOrderCreateState.SUMMARIZE:
        return (
          <QuotationsSummarizeForm
            onPreviousPage={() => setState(QuotationsOrderCreateState.FORM)}
            onSubmit={handleSubmit(handleOnSubmitSummarize)}
          />
        )
    }
  }

  return (
    <QuotationsCreationPageContextImpl.Provider
      value={{
        customerId,
        contactId,
        quotationCreateData,
        customerProfile,
        users: userData,
        ticketId,
      }}
    >
      <FormProvider {...formMethod}>{render()}</FormProvider>
    </QuotationsCreationPageContextImpl.Provider>
  )
}

export default withLayout(QuotationsCreationPage)
