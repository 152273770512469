import { QuotationCreateDataParams } from '.'
import { errorHandler, errorSingleHandler } from '../../middleware'
import { APIResponse, IQuotationTicket } from '../interfaces'
import smartAuthAPI from '../smartbestbuys-api'
import { InsertedResponse, UserActivityNote } from '../types'
import {
  QuotationCancelRequest,
  QuotationRejectResponse,
  QuotationCreateData,
  QuotationCreationRequest,
  QuotationProfileResponse,
  QuotationTakeNoteRequest,
  QuotationTakeNoteResponse,
  QuotationUpdateDataResponse,
  QuotationUpdateRequest,
  QuotationApproveResponse,
  QuotationRejectRequest,
  IQuotationTableData,
  GetQuotationTableDataRequestQueryParam,
  IQuotationReport,
  GetQuotationReportRequestQueryParams,
  PartialUpdateQuotationRequestBody,
  EvalutationQuotationRequest,
} from './quotation-types'

export const quotationsCreateDataAPI = errorHandler<QuotationCreateData>(async (params: QuotationCreateDataParams) => {
  const result = await smartAuthAPI.get('/quotations/create-data', {
    params,
  })
  return result
})

export const createQuotations = errorHandler<{ id: number }>(async (request: QuotationCreationRequest) => {
  return smartAuthAPI.post('/quotations', request)
})

export const fetchQuotationsProfile = errorHandler<QuotationProfileResponse>(async (id: number) => {
  const result = await smartAuthAPI.get(`/quotations/${id}/profile`)
  return result
})

export const createQuotationTakeNote = errorSingleHandler<QuotationTakeNoteResponse>(
  async (id: number, request: QuotationTakeNoteRequest) => {
    return smartAuthAPI.patch(`/quotations/${id}/take-note`, request)
  },
)

export const cancelQuotationsProfile = errorSingleHandler<QuotationRejectResponse>(
  async (id: number, request: QuotationCancelRequest) => {
    return smartAuthAPI.patch(`/quotations/${id}/cancel`, request)
  },
)

export const fetchQuotationsUpdateData = errorHandler<QuotationUpdateDataResponse>(async (id: number) => {
  const result = await smartAuthAPI.get(`/quotations/${id}/update-data`)
  return result
})

export const updateQuotations = errorSingleHandler<{ success?: boolean }>(
  async (id: number, request: QuotationUpdateRequest) => {
    return smartAuthAPI.put(`/quotations/${id}/update`, request)
  },
)

export const patchApproveQuotation = errorSingleHandler<QuotationApproveResponse>(async (id: number) => {
  return smartAuthAPI.patch(`/quotations/${id}/approve`)
})

export const patchRejectQuotation = errorSingleHandler<QuotationRejectResponse>(
  async (id: number, request: QuotationRejectRequest) => {
    return smartAuthAPI.patch(`/quotations/${id}/reject`, request)
  },
)

export const getQuotationTableDataAPI = errorHandler<IQuotationTableData>(
  async (queryParams: GetQuotationTableDataRequestQueryParam) => {
    return await smartAuthAPI.get(`/quotations/table-data`, {
      params: queryParams,
    })
  },
)

export const getQuotationReportAPI = errorHandler<IQuotationReport>(
  async (queryParams: GetQuotationReportRequestQueryParams) => {
    return await smartAuthAPI.get('/quotations/report', {
      params: queryParams,
    })
  },
)

export const approveQuotationDataAPI = errorSingleHandler<{ success?: boolean }>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.post(`/quotations/${id}/approve-review`, request)
  },
)

export const requestChangeQuotationAPI = errorSingleHandler<{ success?: boolean }>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.post(`/quotations/${id}/request-changed`, request)
  },
)

export const partialUpdateQuotation = errorSingleHandler<{ success?: boolean }>(
  async (id: number, request: PartialUpdateQuotationRequestBody) => {
    return smartAuthAPI.patch(`quotations/${id}/partial-update`, request)
  },
)

export const evalutationQuotationAPI = errorSingleHandler<InsertedResponse>(
  async (id: number, request: EvalutationQuotationRequest) => {
    return smartAuthAPI.post(`/api/v2/quotations/${id}/evaluation`, request)
  },
)

export const getQuotationTicketInfoAPI = errorHandler<IQuotationTicket>(async (id: number) => {
  const response = await smartAuthAPI.get<APIResponse<IQuotationTicket>>(`/api/v2/quotation-tickets/${id}/info`)
  return response.data
})
