import { Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import ProductProfileContext from '../ProductProfileContext'
import { useContext, useState } from 'react'
import { FilteredTable, Link } from '../../../../../custom-components'
import { ICustomer, ICustomerContact, IQuotation, IQuotationProduct } from '../../../../../api/smartbestbuys-api'
import { QuotationStatus } from '../../../../../enums'
import { dateFormat } from '../../../../../functions'

const ProductProfileQuotationProcessingHistory: React.FC = (props) => {
  const [tabIndex, setTabIndex] = useState<number>(QuotationStatus.PendingReview)
  const { productProfile } = useContext(ProductProfileContext)
  const { quotationHistory } = productProfile

  const quotations = quotationHistory.filter((q) => q.status.id === tabIndex)

  const quotationPendingReviewAmount = quotationHistory.filter(
    (q) => q.status.id === QuotationStatus.PendingReview,
  ).length
  const quotationPendingAmount = quotationHistory.filter((q) => q.status.id === QuotationStatus.Pending).length

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary">
            รายการใบเสนอราคาที่กำลังดำเนินการ
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, value) => setTabIndex(value)}
            centered
            variant="fullWidth"
          >
            <Tab label={`รอการตรวจสอบ (${quotationPendingReviewAmount})`} value={QuotationStatus.PendingReview} />
            <Tab label={`รอการการยืนยัน (${quotationPendingAmount})`} value={QuotationStatus.Pending} />
          </Tabs>
          <FilteredTable<
            Pick<IQuotation, 'id' | 'code' | 'status' | 'recCreatedAt'> &
              Pick<IQuotationProduct, 'amount' | 'price' | 'productTotal'> & {
                customer: Pick<ICustomer, 'id' | 'nameLocal'>
                customerContact?: Pick<ICustomerContact, 'id' | 'nameLocal' | 'nicknameLocal'>
              }
          >
            color="primary.dark"
            columns={[
              {
                name: 'เลขที่ใบเสนอราคา',
                render: ({ id, code }) => (
                  <Link color="primary" to={`/quotations/${id}/profile`}>
                    {code}
                  </Link>
                ),
              },
              {
                name: 'ชื่อผู้ติดต่อ',
                render: ({ customer, customerContact }) => (
                  <div>{customerContact?.nicknameLocal ?? customer.nameLocal}</div>
                ),
              },
              {
                name: 'จำนวน',
                dataIndex: 'amount',
                numeric: true,
                format: '0,0',
              },
              {
                name: 'ราคา (บาท)',
                dataIndex: 'price',
                numeric: true,
                format: '0,0',
              },
              {
                name: 'จำนวนเงิน',
                dataIndex: 'productTotal',
                numeric: true,
                format: '0,0',
              },
              {
                name: 'วันที่สร้าง',
                dataIndex: 'recCreatedAt',
                render: ({ recCreatedAt }) => dateFormat(recCreatedAt),
              },
            ]}
            data={quotations.sort((a, b) => b.id - a.id)}
            size="small"
            disableDateRangeFilter
            cleanSpace
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProductProfileQuotationProcessingHistory
