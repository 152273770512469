import React from 'react'
import { useQuotationTicket } from '../../../../hooks/quotation/useQuotationTicket'
import { Box, Paper } from '@material-ui/core'
import { SubTopic } from '../../../../custom-components/Typography'
import { Loading } from '../../../../shared-components/Loading'

interface QuotationTicketProps {
  ticketId: number
}

const QuotationTicket: React.FC<QuotationTicketProps> = ({ ticketId }) => {
  const { data: ticket } = useQuotationTicket(ticketId)
  if (!ticket) return <Loading />
  return (
    <Box>
      <Paper className="flex flex-col gap-2">
        <SubTopic>รายละเอียดคำขอใบเสนอราคา</SubTopic>
        <div className="rounded-md border-2 bg-neutral-50 p-4">
          <div>รายละเอียด: {ticket.content}</div>
        </div>
      </Paper>
    </Box>
  )
}

export default QuotationTicket
