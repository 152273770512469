import { Box, Grid } from '@material-ui/core'
import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { Form, Topic } from '../../../../custom-components'
import { CustomerProfileCard, SubmitForm } from '../../../../shared-components'
import QuotationsDiscountBox from '../quotation-presentation/QuotationsDiscountBox'
import QuotationSelectedProduct from '../quotation-presentation/QuotationSelectedProduct'
import QuotationsMoreInformation from '../quotation-presentation/QuotationsMoreInformation'
import QuotationsProductSelector from '../quotation-presentation/QuotationsProductSelector'
import QuotationsShippingFeeBox from '../quotation-presentation/QuotationsShippingFeeBox'
import QuotationsTotalBox from '../quotation-presentation/QuotationsTotalBox'
import QuotationsVatBox from '../quotation-presentation/QuotationsVatBox'
import QuotationTicket from '../quotation-presentation/QuotationTicket'
import { QuotationsCreateValue } from '../QuotationsCreateValue'
import { QuotationsCreationPageContextImpl } from '../QuotationsCreationPage'
import './QuotationsCreationForm.less'

interface QuotationsCreationFormProps {
  onNextPage: () => void
}

const QuotationsCreationForm: React.FC<QuotationsCreationFormProps> = ({ onNextPage }) => {
  const { contactId, customerProfile, ticketId } = useContext(QuotationsCreationPageContextImpl)
  const {
    watch,
    setValue,
    setError,
    formState: { isSubmitting },
  } = useFormContext<QuotationsCreateValue>()
  // preparing data
  const { billingAddressId, selectedProducts, periodDay } = watch()

  const handleSubmit = () => {
    if (!validate()) onNextPage()
  }

  const handleInvalid = (errors: any) => {
    validate()
  }

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'end' })
    }
  }

  const validate = (): boolean => {
    let error = false
    if (selectedProducts.length === 0) {
      setError('selectedProducts', {
        type: 'required',
        message: 'กรุณาเลือกสินค้า/บริการ ลงในใบเสนอราคา',
      })
      error = true
      scrollTo('#quotations-product-selector')
    }
    if (periodDay.toString() !== '' && periodDay <= 0) {
      setError('periodDay', {
        type: 'min',
        message: 'กรุณากรอกระยะยืนราคาไม่ต่ำกว่า 1 วัน',
      })
      error = true
      scrollTo('#quotations-more-information')
    }
    return error
  }

  return (
    <Form onSubmit={handleSubmit} onInvalid={handleInvalid}>
      <Box className="quotation-create-page-header">
        <Topic>สร้างใบเสนอราคา</Topic>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomerProfileCard
            contactId={Number(contactId)}
            customerProfile={customerProfile}
            billingAddressId={billingAddressId}
            billingAddressSelect
            disableBtnCustomerDetail
            onBillingAddressChange={(id: number) => setValue('billingAddressId', id)}
          />
        </Grid>
        {ticketId && (
          <Grid item xs={12}>
            <QuotationTicket ticketId={ticketId} />
          </Grid>
        )}
        <Grid item xs={12} id="quotations-product-selector">
          <QuotationsProductSelector />
        </Grid>
        <Grid item xs={12}>
          <QuotationSelectedProduct isDealer={customerProfile?.isDealer} />
        </Grid>
        <Grid item xs={12} md={6}>
          <QuotationsDiscountBox />
        </Grid>
        <Grid item xs={12} md={6}>
          <QuotationsShippingFeeBox />
        </Grid>
        <Grid item xs={12}>
          <QuotationsVatBox />
        </Grid>
        <Grid item xs={12}>
          <QuotationsTotalBox />
        </Grid>
        <Grid item xs={12} id="quotations-more-information">
          <QuotationsMoreInformation />
        </Grid>
        <Grid item xs={12}>
          <SubmitForm goBack submitText={'ตรวจทานสร้างใบเสนอราคา'} cancelText="ยกเลิก" disableSubmit={isSubmitting} />
        </Grid>
      </Grid>
    </Form>
  )
}

export default QuotationsCreationForm
