import { Grid, Paper, Typography } from '@material-ui/core'
import ProductProfileContext from '../ProductProfileContext'
import { useContext } from 'react'
import { FilteredTable, Link } from '../../../../../custom-components'
import { ICustomer, ISaleOrder, ISaleOrderProduct } from '../../../../../api/smartbestbuys-api'
import { dateFormat } from '../../../../../functions'

const ProductProfileSaleOrderHistory: React.FC = (props) => {
  const { productProfile } = useContext(ProductProfileContext)
  const { doneDeliverSaleOrders } = productProfile

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary">
            รายการใบสั่งขาย ที่ส่งสำเร็จ{' '}
            <Typography variant="body1" color="primary">
              ({doneDeliverSaleOrders.length})
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FilteredTable<
            Pick<ISaleOrder, 'id' | 'code' | 'closedAt'> &
              Pick<ISaleOrderProduct, 'amount' | 'price' | 'productTotal'> & {
                customer: Pick<ICustomer, 'id' | 'code' | 'nameLocal'>
              }
          >
            color="primary.dark"
            columns={[
              {
                name: 'เลขที่ใบสั่งขาย',
                render: ({ id, code }) => (
                  <Link color="primary" to={`/sale-orders/${id}`}>
                    {code}
                  </Link>
                ),
              },
              {
                name: 'ชื่อลูกค้า',
                render: ({ customer }) => <div>{customer.nameLocal}</div>,
              },
              {
                name: 'จำนวน',
                dataIndex: 'amount',
                numeric: true,
                format: '0,0',
              },
              {
                name: 'ราคา (บาท)',
                dataIndex: 'price',
                numeric: true,
                format: '0,0',
              },
              {
                name: 'จำนวนเงิน',
                dataIndex: 'productTotal',
                numeric: true,
                format: '0,0',
              },
              {
                name: 'วันที่ปิดการขาย',
                dataIndex: 'closedAt',
                render: ({ closedAt }) => (closedAt ? dateFormat(closedAt) : '-'),
              },
            ]}
            data={doneDeliverSaleOrders.sort((a, b) => b.id - a.id)}
            size="small"
            disableDateRangeFilter
            cleanSpace
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProductProfileSaleOrderHistory
