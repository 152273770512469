import { useEffect, useState } from 'react'
import { IQuotationTicket } from '../../api/smartbestbuys-api'
import { getQuotationTicketInfoAPI } from '../../api/smartbestbuys-api/quotation/quotation-apis'

export const useQuotationTicket = (ticketId?: number) => {
  const [data, setData] = useState<IQuotationTicket | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const fetchQuotationTicket = async (id: number) => {
    try {
      setLoading(true)
      const response = await getQuotationTicketInfoAPI(id)
      setData(response?.data)
    } catch (err: any) {
      setError(err.message || 'Failed to fetch quotation ticket.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (ticketId) {
      fetchQuotationTicket(ticketId)
    }
  }, [ticketId])

  return { data, loading, error }
}
